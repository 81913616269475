import { CardsOverview } from './CardsOverview'
import { CarouselOverview } from './CarouselOverview'
import NavbarComponent from './NavbarOverview'
import FooterOverview from './FooterOverview'
import 'bootstrap/dist/css/bootstrap.css';

export  const HomeOverview = () => {
  return (
    <>
      <NavbarComponent/>
      <CarouselOverview />
      <br/>
      <CardsOverview/>
      <FooterOverview/>
    </>
  )
}


