import React from 'react'
import  '../components/styles/body_sectioncenter.css'
import { Link } from 'react-router-dom';
export const   CardsOverview=()=> {
  return (
    <div>
    <section id="gallery">
  <div className="container">
    <div className="row">
    <div className="col-lg-4 mb-4">
    <div className="card">
      <img src="http://tierzuchtklause-bayreuth.de/bilder/Aktionen.png" alt="" className="card-img-top"/>
      <div className="card-body">
        <h5 className="card-title">Aktionen</h5>
        <p className="card-text">Sie wollen wissen welche Aktionen die Tierzuchtklause anbietet </p>
       {/* <a href="" className="btn btn-outline-success btn-sm">Read More</a> */}
       <Link to="/aktionen"> <p  className="btn btn-outline-success btn-sm">Read More</p></Link>
      </div>
     </div>
    </div>
  <div className="col-lg-4 mb-4">
  <div className="card">
      <img src="http://tierzuchtklause-bayreuth.de/bilder/Kontakt.png" alt="" className="card-img-top" />
      <div className="card-body">
        <h5 className="card-title">Kontakt</h5>
        <p className="card-text">Sie wollen den Partyservice bei uns buchen treten Sie mit uns in Kontakt</p>
       <a href="tel:+491709826965" className="btn btn-outline-success btn-sm">Read More</a>
      
      </div>
      </div>
    </div>
    <div className="col-lg-4 mb-4">
    <div className="card">
      <img src="http://tierzuchtklause-bayreuth.de/bilder/Speisen.png" alt="" className="card-img-top"/>
      <div className="card-body">
        <h5 className="card-title">SpeiseKarte</h5>
        <p className="card-text">Sie wollen für unseren PartyService essen bestellen. Dann schauen Sie doch mal unsere leckere Speisekarte an </p>
       <a href="http://tierzuchtklause-bayreuth.de/speisekarte/catering.pdf" className="btn btn-outline-success btn-sm">Read More</a>
      </div>
     </div>
    </div>
  </div>
</div>
</section>
 {/* <br/>
 <div className="body_sectioncenter">
<div className="row featurette">
      <div className="col-md-7">
        <h2 className="featurette-heading fw-normal lh-1"> <b>Chefkoch Gerhard  Opel</b></h2>

       
        <p className="lead">
        Text.

        </p>
        <br/>
        <h5 className="featurette-heading fw-normal lh-1"> <b>Text.</b></h5>
         <p className="lead">
     Text.
       
        </p>
      </div>
      <div className="col-md-5">
        <img src="https://images.unsplash.com/photo-1477862096227-3a1bb3b08330?ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60" className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500"  alt="right_pic01"/>
      </div>
    </div>
</div> */}
</div>
  )
}

