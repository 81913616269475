import React from 'react'

export const ImpressumOverview = () => {
  return (
    // <div>ImpressumOverview</div>

    <>
    <div>
    <br/>
        <h1>Impressum</h1>
<br/> <br/>
        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <br/>
        
        <p>Gehard Opel<br /> Gaststätte Tierzuchtklause Bayreuth
            <br />  Adolf-Wächter-Str.9<br /> 95447 Bayreuth</p>
<br/> <br/>
        <h2>Kontakt</h2>
        <br/> 
        <p>Telefon: 0921 50720250<br/> Handy: 0170 9826965<br /> E-Mail: Gopel@t-online.de</p>


        {/* <h2>Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br /> DE 999 999 999</p> */}

<br/> <br/>
        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <br/> 
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        <br/> <br/>
        <h2>Bilder</h2>
        <br/>
        <p>Die Bilder unserer Website <a href="http://www.tierzuchtklause-bayreuth.de/">Gaststätte Tierzuchtklause Bayreuth</a> wurden selbst erstellt </p>
        <br/> <br/>
        <h2>Quelle</h2>
        <br/> 
         <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a>
       
       
        <br/>

    </div>
    </>
  )
}
