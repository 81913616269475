import { CarouselOverview } from './CarouselOverview'
import '../components/styles/timeline.css';
import '../components/styles/h2.css'
import NavbarComponent from './NavbarOverview'
import FooterOverview from './FooterOverview';
import 'bootstrap/dist/css/bootstrap.css';
export const AboutOverview = () => {
  return (

    <>
    <NavbarComponent/>
     <CarouselOverview />
                  <div>

                   
                    <div className="container">
                      <br/>
                    <h2 className='headlineTimestamp'>Zeitliche Geschichte</h2>
                    <br/>
                      <div className="main-timeline" >
                        <br/>
                      
                       
                        <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                            <span className="month">27 Jahre</span>
                                    <span className="year">1985</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className="title">Neunkirchen Gastätte</h5>
                                <p className="description">
                                    
                                Die Gaststätte hatte täglich für ihre Kunden geöffnet.   
                                Neben den leckeren Speisen herrschte auch immer eine gemütliche und entspannte Atmosphäre. 
                                </p>
                            </div>
                        </div>
                       
                        <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                    <span className="month">ab</span>
                                    <span className="year">2010</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className="title">Gastätte Tierzuchtklause Bayreuth</h5>
                                <p className="description">
                                Seit wir die Gaststätte Tierzuchtklause betreiben konzentrieren wir uns auf Veranstaltungen vor 
                                Ort und Catering für z.b. Geburtstage oder Firmenfeiern.
                                </p>
                            </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <br/>
                  <FooterOverview/>
    </>
    
  )
}

