
import './styles/footer.scss';
import './styles/hyperlinks.css';
import { Link } from 'react-router-dom';

export default function FooterOverview() {
  return (
<div className="mt-5 pt-5 pb-5 footer">
<div className="container">
  <div className="row">
    <div className="col-lg-5 col-xs-12 about-company">
      <h2>Gaststätte Tierzuchtklause Bayreuth</h2>
      <p className="pr-5 text-white-50">Hatel kocht </p>
      <p><a href="https://www.facebook.com/Gaststätte-Tierzuchtklause-706648169444282/?ref=page_internal"><i className="bi bi-facebook"></i></a></p>
    </div>
    <div className="col-lg-3 col-xs-12 links">
      <h4 className="mt-lg-0 mt-sm-3">Rechtlich</h4>
        <ul className="m-0 p-0">
          <li> <Link to="/datenschutz"> <p >Datenschutz</p></Link></li>
          <li> <Link to="/impressum"> <p >Impressum</p></Link></li>
        </ul>
    </div>
    <div className="col-lg-4 col-xs-12 location">
      <h4 className="mt-lg-0 mt-sm-4">Adresse</h4>
      <p className="mb-0">Gerhard Opel</p>
      <p className="mb-0">Adolf-Wächter-Str.9</p>
      <a className="mb-0" href='tel:+4992150720250'><i className="fa fa-phone mr-3"></i>0921 50720250</a> <br/>
      <a className="mb-0" href='tel:+491709826965'><i className="fa fa-phone mr-3"></i>0170 9826965</a> <br/>
      <a className='mb-0' href='mailto:Gopel@t-online.de'><i className="fa fa-envelope-o mr-3"></i>Gopel@t-online.de</a>
    </div>
  </div>
  <div className="row mt-5">
    <div className="col copyright">
    <p className=""><small className="text-white-50">{new Date().getFullYear()} Made by:{' '}   <a className='text-white-50' href='https://www.getyourownwebsite.de'>
            getyourownwebsite.de
         </a></small></p>
    </div>
  </div>
</div>
</div>
  )
}
