import { useRouteError } from "react-router-dom";
//import '../style/errorstyle.css'
export const ErrorPageOverview = () => {

  
    const error = useRouteError();
  console.error(error);

  return (
    <>
    <h1 className="mx-auto">Error 404 </h1>
    <h3>Seite kann nicht gefunden werden </h3>
   <a href="http://tierzuchtklause-bayreuth.de">Weiterleitung zur Gaststätte  Tierzuchtklause Bayreuth</a>
    {/* <div className="container">
  <h1 className="first-four">4</h1>
  <div className="cog-wheel1">
      <div className="cog1">
        <div className="top"></div>
        <div className="down"></div>
        <div className="left-top"></div>
        <div className="left-down"></div>
        <div className="right-top"></div>
        <div className="right-down"></div>
        <div className="left"></div>
        <div className="right"></div>
    </div>
  </div>
  
  <div className="cog-wheel2"> 
    <div className="cog2">
        <div className="top"></div>
        <div className="down"></div>
        <div className="left-top"></div>
        <div className="left-down"></div>
        <div className="right-top"></div>
        <div className="right-down"></div>
        <div className="left"></div>
        <div className="right"></div>
    </div>
  </div>
 <h1 className="second-four">4</h1>
 <a className="wrong-para"  href="http://tierzuchtklause-bayreuth.de/home">Ein Fehler ist aufgetreten klick mich um zur Website zu kommen</a>
</div> */}
    </>
  );
}