import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import './styles/hyperlinks.css';
import Navbar from 'react-bootstrap/Navbar';
import {
 
  Link,
 
} from "react-router-dom";




function NavbarOverview() {
  return (

    <div>
   <>
   <Navbar expand="lg" variant="light" bg="light">
        <Container>
          <a href='tel:+491709826965' className="h6 mx-auto" >0921 50720250 / 0170 9826965</a>
          <a href='mailto:Gopel@t-online.de' className="h6 mx-auto">Gopel@t-online.de</a>
        </Container>
      </Navbar> 
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} className='fw-bold' to="/">Tierzuchtklause Bayreuth</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link as={Link} to="/" >Home</Nav.Link>
            <Nav.Link as={Link} to="/about">Über uns</Nav.Link>
            <Nav.Link as={Link} to="/partyservice">Partyservice</Nav.Link>
            <Nav.Link href='http://tierzuchtklause-bayreuth.de/speisekarte/catering.pdf'>Speisekarte</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>

<>


</>
</div>
  );
}

export default NavbarOverview;