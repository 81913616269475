import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AboutOverview } from './components/AboutOverview';
import { MenuOverview } from './components/MenuOverview';
import { PartyServiceOverview } from './components/PartyServiceOverview';
import { ImpressumOverview } from './components/ImpressumOverview';
import { DatenschutzOverview } from './components/DatenschutzOverview';
import { ErrorPageOverview } from './components/ErrorPage';
import { HomeOverview } from './components/HomeOverview';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeOverview />,
    errorElement: <ErrorPageOverview />,
  },
  {
    path: "/about",
    element: <AboutOverview />,
    errorElement: <ErrorPageOverview />,
  },
  {
    path: "/menu",
    element: <MenuOverview />,
    errorElement: <ErrorPageOverview />,
  },
  {
    path: "/partyservice",
    element: <PartyServiceOverview />,
    errorElement: <ErrorPageOverview />,
  },
  {
    path: "/impressum",
    element: <ImpressumOverview />,
    errorElement: <ErrorPageOverview />,
  },
  {
    path: "/datenschutz",
    element: <DatenschutzOverview />,
    errorElement: <ErrorPageOverview />

  },
  
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
