import { CarouselOverview } from './CarouselOverview'
import  '../components/styles/body_sectioncenter.css'
import NavbarComponent from './NavbarOverview'
import FooterOverview from './FooterOverview'
import 'bootstrap/dist/css/bootstrap.css';
export const PartyServiceOverview = () => {
  return (
    <>
    <NavbarComponent/>
    <CarouselOverview />

<br/>
    <div className="body_sectioncenter">
<div className="row featurette">
      <div className="col-md-7">
        <h2 className="featurette-heading fw-normal lh-1"><b>Unser Partyservice</b></h2>

       
        <p className="lead">
        Wir organisieren Ihre Familie, Vereine und Betriebsfeste auch an Sonntag und an den Feiertagen.

        Wir beliefern auch ihre Veranstaltung und Feierlichkeiten in unserer Gaststätte

        </p>
        <br/>
        <h5 className="featurette-heading fw-normal lh-1"> <b>Speisekarte</b></h5>
         <p className="lead">
         Hier Finden Sie unsere Speisekarte mit leckeren bayrischen Gerichten
       
        </p>
        <a href='http://tierzuchtklause-bayreuth.de/speisekarte/catering.pdf' className='btn btn-dark'>Speisekarte</a>
        <br/>
        <br/>
        <h5 className="featurette-heading fw-normal lh-1"> <b>Kontakt</b></h5>
        <p className="lead">
        Bei Fragen oder Wünschen zu Ihrem Catering oder Ihrer Feier, 
        schreiben Sie uns eine Nachricht oder rufen Sie an unter 
       
        </p>
        <a href='tel:+491709826965' className='btn btn-dark'>Anrufen</a>
         <p className="lead">
         Wir freuen uns auf Sie
       
        </p>
      </div>
      <div className="col-md-5">
        <img src="http://tierzuchtklause-bayreuth.de/bilder/Cateringpic.png" className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500"  alt="right_pic01"/>
      </div>
    </div>
</div>
<br/>
<FooterOverview/>
    </>
  )
}
